<template>
    <section id="features" class="feature-section ptb-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-4">
                    <div class="download-img">
                        <img
                            src="img/image-14.png"
                            alt="download"
                            class="img-fluid"
                        />
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="feature-contents section-heading">
                        <h2>
                            Share your photos with <br />
                            friends easily
                        </h2>
                        <p>
                            Objectively deliver professional value with diverse
                            web-readiness. Collaboratively transition wireless
                            customer service without goal-oriented catalysts for
                            change. Collaboratively.
                        </p>
                        <p>
                            Uniquely simplify sustainable applications whereas
                            adaptive schemas. Competently brand performance
                            based content and.
                        </p>

                        <ul class="list-inline mt-5">
                            <li class="list-inline-item">
                                <div class="mr-3 icon-box border">
                                    <img
                                        src="img/image-icon-2.png"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <div class="mr-3 icon-box border">
                                    <img
                                        src="img/image-icon-3.png"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <div class="mr-3 icon-box border">
                                    <img
                                        src="img/image-icon-4.png"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                            </li>
                            &nbsp;
                            <li class="list-inline-item">
                                <div class="mr-3 icon-box border">
                                    <img
                                        src="img/image-icon-1.png"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Features",
};
</script>

<style>
</style>