<template>
    <div class="main">
        <section
            class="hero-section ptb-100 background-img full-screen  banner-1-bg"
            
        >
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-9 col-lg-7">
                        <div class="hero-content-left text-white text-center">
                            <h1 class="text-white">Thank You !</h1>

                            <p class="lead">
                                We will be in touch shortly. We're looking
                                forward to your demo. If you have any questions
                                prior to your demo feel free to give us a call.
                            </p>

                            <div class="mb-5">
                                <h5 class="mb-0 text-white">
                                    <span
                                        class="ti-mobile mr-2 align-items-center"
                                    ></span>
                                    (612) 255-461
                                </h5>
                            </div>
                            <form
                                action="#"
                                method="post"
                                class="subscribe-form text-center m-auto"
                            >
                                <div class="d-flex align-items-center">
                                    <input
                                        type="text"
                                        class="form-control input"
                                        id="email"
                                        name="email"
                                        placeholder="info@yourdomain.com"
                                    />
                                    <input
                                        type="submit"
                                        class="button btn solid-btn"
                                        id="submit"
                                        value="Subscribe"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: "ThankyouPage",
    
};
</script>
<style lang="scss" scoped>
.banner-1-bg {
    background: url("../assets/img/hero-bg-1.jpg") no-repeat center center /
        cover !important;
}
</style>